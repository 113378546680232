// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
// Assets
import { MdLock, MdUpload } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormRequest from "views/admin/formRequest";
import Dropzone from "views/admin/profile/components/Dropzone";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory();
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    if (selectedCompany) {
      setCompanyData(selectedCompany);
    }
  }, []);

  if (!companyData) {
    return null; // Or render a loading state or a message indicating no data
  }

  const {
    companyType,
    companyBasedIn,
    companyAddress,
    afsIssuedDate,
    financialYearEndDate,
    companyBusiness,
    originalCertificateIssuedDate,
    icvScoreValidTill,
    licenceNumber,
    companyName,
    companyLogo,
    ICVScore,
    companyEmail,
    icvCertificatePath,
    status,
  } = companyData;

  let userRole = localStorage.getItem("userRole");

  const handleDropzoneClick = () => {
    if (status === "Approved") {
      const baseUrl = "http://localhost:3000";
      const fullPath = `${baseUrl}${icvCertificatePath}`;
      window.open(fullPath, "_blank");
    } else {
      alert("ICV Certificate is not approved yet.");
    }
  };

  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      <Flex h="100%" direction={{ base: "column", "2xl": "row" }}>
        <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me="36px"
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box onClick={handleDropzoneClick}>
              <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
              <Flex justify="center" mx="auto" mb="12px">
                <Text fontSize="xl" fontWeight="700" color={brandColor}>
                  ICV Certificate
                </Text>
              </Flex>
              <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                Download ICV Certificate
              </Text>
            </Box>
          }
        />
        <Flex direction="column" pe="44px">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            textAlign="start"
            fontSize="2xl"
            mt={{ base: "20px", "2xl": "50px" }}
          >
            AICV Calculate Score
          </Text>
          <Text
            color={textColorSecondary}
            fontSize="md"
            my={{ base: "auto", "2xl": "10px" }}
            mx="auto"
            textAlign="start"
          >
            Stay on the pulse of distributed projects with an anline whiteboard
            to plan, coordinate and discuss
          </Text>
          <Flex w="100%">
            <Button
              me="100%"
              mb="50px"
              w="140px"
              minW="140px"
              mt={{ base: "20px", "2xl": "auto" }}
              variant="brand"
              fontWeight="500"
              onClick={() =>
                history.push({
                  pathname: "/admin/form-request",
                  state: {
                    name: "Form Request",
                    icon: <MdLock width="20px" height="20px" color="inherit" />,
                    component: FormRequest,
                  },
                })
              }
            >
              {userRole === 'aicv' ? (status === "Approved" ? "Approved" : "Approve now") :
                (status === "Approved" ? "Approved" : "View Application")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
