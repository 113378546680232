export const columnsDataDevelopment = [
  {
    Header: "COMPANY NAME",
    accessor: "COMPANY NAME",
  },
  {
    Header: "STATUS",
    accessor: "STATUS",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ICV SCORE",
    accessor: "ICV SCORE",
  },
  {
    Header: "ACTION",
    accessor: "ACTION",
  },
];

export const columnsDataDevelopmentCompany = [
  {
    Header: "ICV ID",
    accessor: "ICV ID",
  },
  {
    Header: "STATUS",
    accessor: "STATUS",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ICV SCORE",
    accessor: "ICV SCORE",
  },
  {
    Header: "ACTION",
    accessor: "ACTION",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
