// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React, { useEffect, useState } from "react";
// Assets
import { MdOutlineCloudDone } from "react-icons/md";

export default function Banner(props) {
  const { used, total } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    if (selectedCompany) {
      setCompanyData(selectedCompany);
    }
  }, []);

  if (!companyData) {
    return null; // Or render a loading state or a message indicating no data
  }

  const {
    companyType,
    companyBasedIn,
    companyAddress,
    afsIssuedDate,
    financialYearEndDate,
    companyBusiness,
    originalCertificateIssuedDate,
    icvScoreValidTill,
    licenceNumber,
    companyName,
    companyLogo,
    ICVScore,
    companyEmail,
    status,
  } = companyData;

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Flex w="100%">
        <Menu ms="auto" />
      </Flex>
      <IconBox
        mx="auto"
        h="100px"
        w="100px"
        icon={
          <Icon as={MdOutlineCloudDone} color={brandColor} h="46px" w="46px" />
        }
        bg={box}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px">
        {ICVScore}
      </Text>
      <Text
        color={textColorSecondary}
        fontSize="md"
        maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
        mx="auto"
      >
        In-Country Value Score
      </Text>
      <Box w="100%" mt="auto">
        <Flex w="100%" justify="space-between" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {status}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {status === "Approved" ? "100%" : status === "" ? "0%" : "40%"}
          </Text>
        </Flex>
        <Progress
          align="start"
          colorScheme="brandScheme"
          value={status === "Approved" ? 100 : status === "" ? 0 : 40}
          w="100%"
        />
      </Box>
    </Card>
  );
}
