import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const GoodsManufacturedAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.goodsManufactured);
  }, []);

  const handleInputChange = (e, section, field, index) => {
    if (index !== undefined) {
      setFormValues({
        ...formValues,
        [section]: formValues[section].map((item, i) =>
          i === index ? { ...item, [field]: e.target.value } : item
        ),
      });
    } else {
      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          [field]: e.target.value,
        },
      });
    }
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0, 1, 2, 3, 4, 5]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Goods Manufactured
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.goods?.map((good, index) => (
                <SimpleGrid columns={2} spacing={4} key={index}>
                  <Box>
                    <FormLabel>S. No</FormLabel>
                    <Input
                      value={good.serialNumber || ""}
                      onChange={(e) =>
                        handleInputChange(e, "goods", "serialNumber", index)
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Goods Description</FormLabel>
                    <Input
                      value={good.description || ""}
                      onChange={(e) =>
                        handleInputChange(e, "goods", "description", index)
                      }
                      placeholder="Text"
                    />
                  </Box>
                </SimpleGrid>
              ))}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Within UAE - Third Party Cost (Goods Manufacturer)
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.withinUAEThirdPartyCostGoodsManufacturer?.map(
                (item, index) => (
                  <SimpleGrid columns={2} spacing={4} key={index}>
                    <Box>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={item.name || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostGoodsManufacturer",
                            "name",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                    <Box>
                      <FormLabel>Location</FormLabel>
                      <Input
                        value={item.location || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostGoodsManufacturer",
                            "location",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                    <Box>
                      <FormLabel>Procured Cost (AED)</FormLabel>
                      <Input
                        value={item.procuredCost || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostGoodsManufacturer",
                            "procuredCost",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                  </SimpleGrid>
                )
              )}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Within UAE - Third Party Cost (Service Provider)
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.withinUAEThirdPartyCostServiceProvider?.map(
                (item, index) => (
                  <SimpleGrid columns={2} spacing={4} key={index}>
                    <Box>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={item.name || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostServiceProvider",
                            "name",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                    <Box>
                      <FormLabel>Location</FormLabel>
                      <Input
                        value={item.location || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostServiceProvider",
                            "location",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                    <Box>
                      <FormLabel>ICV%</FormLabel>
                      <Input
                        value={item.icvPercentage || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostServiceProvider",
                            "icvPercentage",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                    <Box>
                      <FormLabel>ICV Value (AED)</FormLabel>
                      <Input
                        value={item.icvValue || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "withinUAEThirdPartyCostServiceProvider",
                            "icvValue",
                            index
                          )
                        }
                        placeholder="Text"
                      />
                    </Box>
                  </SimpleGrid>
                )
              )}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Within UAE
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <FormLabel>Internal Cost</FormLabel>
              <Input
                value={formValues.withinUAEInternalCost || ""}
                onChange={(e) =>
                  handleInputChange(e, "withinUAEInternalCost", "")
                }
                placeholder="Text"
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Outside UAE - Third Party Cost
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.outsideUAEThirdPartyCost?.map((item, index) => (
                <SimpleGrid columns={2} spacing={4} key={index}>
                  <Box>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={item.name || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "outsideUAEThirdPartyCost",
                          "name",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Location</FormLabel>
                    <Input
                      value={item.location || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "outsideUAEThirdPartyCost",
                          "location",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Procured Cost (AED)</FormLabel>
                    <Input
                      value={item.procuredCost || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "outsideUAEThirdPartyCost",
                          "procuredCost",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>ICV%</FormLabel>
                    <Input
                      value={item.icvPercentage || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "outsideUAEThirdPartyCost",
                          "icvPercentage",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>ICV Value (AED)</FormLabel>
                    <Input
                      value={item.icvValue || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "outsideUAEThirdPartyCost",
                          "icvValue",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                </SimpleGrid>
              ))}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Outside UAE
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <FormLabel>Internal Cost (AED)</FormLabel>
              <Input
                value={formValues.outsideUAEInternalCost || ""}
                onChange={(e) =>
                  handleInputChange(e, "outsideUAEInternalCost", "")
                }
                placeholder="Text"
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
