import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const ManpowerEmiratizationAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.manpowerEmiratization);
  }, []);

  const handleInputChange = (e, section, field) => {
    setFormValues({
      ...formValues,
      [section]: {
        ...formValues[section],
        [field]: e.target.value,
      },
    });
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Direct Staff - Emiratis
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Number</FormLabel>
                  <Input
                    value={formValues.directStaffEmiratis?.number || ""}
                    onChange={(e) =>
                      handleInputChange(e, "directStaffEmiratis", "number")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>
                    Donations to Sondoaq Al Watan/MANAN/Government Universities
                    (AED)
                  </FormLabel>
                  <Input
                    value={
                      formValues.directStaffEmiratis
                        ?.donationsToSondoaqAlWatan || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "directStaffEmiratis",
                        "donationsToSondoaqAlWatan"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>ANNUAL Training Expenditure (AED)</FormLabel>
                  <Input
                    value={
                      formValues.directStaffEmiratis
                        ?.annualTrainingExpenditure || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "directStaffEmiratis",
                        "annualTrainingExpenditure"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Total (AED)</FormLabel>
                  <Input
                    value={formValues.directStaffEmiratis?.total || ""}
                    onChange={(e) =>
                      handleInputChange(e, "directStaffEmiratis", "total")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>ANNUAL Salary + Benefits (AED)</FormLabel>
                  <Input
                    value={
                      formValues.directStaffEmiratis?.annualSalaryBenefits || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "directStaffEmiratis",
                        "annualSalaryBenefits"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
