import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const ThirdPartySpendAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    console.log(
      "selectedCompany.thirdPartySpend :",
      selectedCompany.thirdPartySpend
    );
    setFormValues(selectedCompany.thirdPartySpend);
  }, []);

  const handleInputChange = (e, section, field, index) => {
    if (index !== undefined) {
      setFormValues({
        ...formValues,
        [section]: formValues[section].map((item, i) =>
          i === index ? { ...item, [field]: e.target.value } : item
        ),
      });
    } else {
      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          [field]: e.target.value,
        },
      });
    }
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Third Party Spend
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.procurement?.map((item, index) => (
                <SimpleGrid columns={2} spacing={4} key={index}>
                  <Box>
                    <FormLabel>Cost of Procurement (AED)</FormLabel>
                    <Input
                      value={item.costOfProcurement || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "procurement",
                          "costOfProcurement",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>
                      License Country Procurement Value (AED)
                    </FormLabel>
                    <Input
                      value={item.licenseCountryProcurementValue || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "procurement",
                          "licenseCountryProcurementValue",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Location</FormLabel>
                    <Input
                      value={item.location || ""}
                      onChange={(e) =>
                        handleInputChange(e, "procurement", "location", index)
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Vendor ICV%</FormLabel>
                    <Input
                      value={item.vendorIcvPercentage || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "procurement",
                          "vendorIcvPercentage",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                </SimpleGrid>
              ))}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Total Third Party ICV%
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              {formValues.totalThirdPartyIcv?.map((item, index) => (
                <SimpleGrid columns={2} spacing={4} key={index}>
                  <Box>
                    <FormLabel>Goods / Services Description</FormLabel>
                    <Input
                      value={item.description || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "totalThirdPartyIcv",
                          "description",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                  <Box>
                    <FormLabel>Vendor Name</FormLabel>
                    <Input
                      value={item.vendorName || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "totalThirdPartyIcv",
                          "vendorName",
                          index
                        )
                      }
                      placeholder="Text"
                    />
                  </Box>
                </SimpleGrid>
              ))}
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Outside UAE
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <FormLabel>Internal Cost (AED)</FormLabel>
              <Input
                value={formValues.outsideUAEInternalCost || ""}
                onChange={(e) =>
                  handleInputChange(e, "outsideUAEInternalCost", "")
                }
                placeholder="Text"
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
