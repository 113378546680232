/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Icon,
  Thead,
  Modal,
  Select,
  Input,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tr,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdLock,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import FormRequest from "views/admin/formRequest";

import formData from "../..//formRequest/components/form-request.json"; // Import the JSON data
import moment from "moment";

export default function DevelopmentTable(props) {
  const { columnsData } = props;
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [suggestion, setSuggestion] = useState("");

  useEffect(() => {
    // Store the JSON data in local storage
    let storedData = JSON.parse(localStorage.getItem("companyData"));
    if (!storedData)
      localStorage.setItem("companyData", JSON.stringify(formData));

    // Read the data from local storage
    storedData = JSON.parse(localStorage.getItem("companyData"));

    console.log("storedData :", storedData);

    // Filter companies where status is not empty
    const filteredData = Object.values(storedData.companies)
      .filter((company) => company.status !== "")
      .map((company) => ({
        "COMPANY NAME": company.companyName,
        STATUS: company.status,
        date: company.icvRequestDate || moment().format("DD MMM YYYY"),
        "ICV SCORE": parseFloat(company.ICVScore),
        ACTION: "View / Edit",
        originalData: company,
      }));

    setTableData(filteredData);
  }, []);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectValue(value);
    let suggestionText = "";

    switch (value) {
      case "5%":
        suggestionText = (
          <ul>
            <li>
              Recruit <b>12 Emiratis</b> with a monthly salary of{" "}
              <b>45,000 AED</b> each.
            </li>
            <li>
              Invest <b>1.2 million AED</b> in the local market to enhance
              procurement strategies and local supplier partnerships.
            </li>
          </ul>
        );
        break;
      case "10%":
        suggestionText = (
          <ul>
            <li>
              Increase your local hiring by <b>20 employees</b> with an average
              monthly salary of <b>50,000 AED</b>.
            </li>
            <li>
              Implement <b>5 new training programs</b>.
            </li>
            <li>
              Invest <b>2 million AED</b> in community development projects.
            </li>
          </ul>
        );
        break;
      case "15%":
        suggestionText = (
          <ul>
            <li>
              Recruit <b>30 Emiratis</b> with a monthly salary of{" "}
              <b>55,000 AED</b> each.
            </li>
            <li>
              Invest <b>3 million AED</b> in local market projects.
            </li>
            <li>
              Enhance corporate social responsibility initiatives with{" "}
              <b>10 new programs</b>.
            </li>
          </ul>
        );
        break;
      default:
        suggestionText = "";
    }

    setSuggestion(suggestionText);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          ICV Request List
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "COMPANY NAME") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align="center">
                        <Icon
                          w="24px"
                          h="24px"
                          me="5px"
                          color={
                            cell.value === "Approved"
                              ? "green.500"
                              : cell.value === "Disable"
                              ? "red.500"
                              : cell.value === "Error"
                              ? "orange.500"
                              : null
                          }
                          as={
                            cell.value === "Approved"
                              ? MdCheckCircle
                              : cell.value === "Disable"
                              ? MdCancel
                              : cell.value === "Error"
                              ? MdOutlineError
                              : null
                          }
                        />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TECH") {
                    data = (
                      <Flex align="center">
                        {cell.value.map((item, key) => {
                          if (item === "apple") {
                            return (
                              <AppleLogo
                                key={key}
                                color={iconColor}
                                me="16px"
                                h="18px"
                                w="15px"
                              />
                            );
                          } else if (item === "android") {
                            return (
                              <AndroidLogo
                                key={key}
                                color={iconColor}
                                me="16px"
                                h="18px"
                                w="16px"
                              />
                            );
                          } else if (item === "windows") {
                            return (
                              <WindowsLogo
                                key={key}
                                color={iconColor}
                                h="18px"
                                w="19px"
                              />
                            );
                          }
                        })}
                      </Flex>
                    );
                  } else if (cell.column.Header === "DATE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ICV SCORE") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.value}%
                        </Text>
                        <Progress
                          variant="table"
                          colorScheme="brandScheme"
                          h="8px"
                          w="63px"
                          value={cell.value}
                        />
                      </Flex>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<ChevronDownIcon />}
                          variant="outline"
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              const selectedCompany = row.original.originalData;
                              localStorage.setItem(
                                "selectedCompany",
                                JSON.stringify(selectedCompany)
                              );
                              history.push("/admin/profile");
                            }}
                          >
                            View / Edit
                          </MenuItem>
                          <MenuItem onClick={() => setIsModalOpen(true)}>
                            Improve ICV Score
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ICV Score Improvement Suggestions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>Select Improvement Percentage:</Text>
            <Select
              placeholder="Select percentage"
              value={selectValue}
              onChange={handleSelectChange}
            >
              <option value="5%">5%</option>
              <option value="10%">10%</option>
              <option value="15%">15%</option>
            </Select>
            <Text mt={4}>{suggestion}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
// ==================================
export function DevelopmentTableCompany(props) {
  const { columnsData } = props;
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [suggestion, setSuggestion] = useState("");

  useEffect(() => {
    let storedData = JSON.parse(localStorage.getItem("companyData"));
    if (!storedData)
      localStorage.setItem("companyData", JSON.stringify(formData));

    storedData = JSON.parse(localStorage.getItem("companyData"));

    console.log("=====>", storedData);

    const filteredData = Object.values(storedData.companies)
      .filter((company) => company.status !== "")
      .map((selectedCompany) => ({
        "ICV ID": selectedCompany.requestId || "N/A",
        STATUS: selectedCompany.status || "N/A",
        DATE: selectedCompany.icvRequestDate || moment().format("DD MMM YYYY"),
        "ICV SCORE": selectedCompany.ICVScore || "N/A",
        ACTION: "View / Edit",
        originalData: selectedCompany,
      }));

    setTableData(filteredData);
  }, []);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectValue(value);
    let suggestionText = "";

    switch (value) {
      case "5%":
        suggestionText = (
          <ul>
            <li>
              Recruit <b>12 Emiratis</b> with a monthly salary of{" "}
              <b>45,000 AED</b> each.
            </li>
            <li>
              Invest <b>1.2 million AED</b> in the local market to enhance
              procurement strategies and local supplier partnerships.
            </li>
          </ul>
        );
        break;
      case "10%":
        suggestionText = (
          <ul>
            <li>
              Increase your local hiring by <b>20 employees</b> with an average
              monthly salary of <b>50,000 AED</b>.
            </li>
            <li>
              Implement <b>5 new training programs</b>.
            </li>
            <li>
              Invest <b>2 million AED</b> in community development projects.
            </li>
          </ul>
        );
        break;
      case "15%":
        suggestionText = (
          <ul>
            <li>
              Recruit <b>30 Emiratis</b> with a monthly salary of{" "}
              <b>55,000 AED</b> each.
            </li>
            <li>
              Invest <b>3 million AED</b> in local market projects.
            </li>
            <li>
              Enhance corporate social responsibility initiatives with{" "}
              <b>10 new programs</b>.
            </li>
          </ul>
        );
        break;
      default:
        suggestionText = "";
    }

    setSuggestion(suggestionText);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          ICV Request List
        </Text>
        <Menu>
          <MenuButton as={IconButton} icon={<AddIcon />} variant="outline" />
          <MenuList>
            <MenuItem onClick={() => history.push("/admin/form-request")}>
              Add New Request
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "ICV ID") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align="center">
                        <Icon
                          w="24px"
                          h="24px"
                          me="5px"
                          color={
                            cell.value === "Approved"
                              ? "green.500"
                              : cell.value === "Disable"
                              ? "red.500"
                              : cell.value === "Error"
                              ? "orange.500"
                              : null
                          }
                          as={
                            cell.value === "Approved"
                              ? MdCheckCircle
                              : cell.value === "Disable"
                              ? MdCancel
                              : cell.value === "Error"
                              ? MdOutlineError
                              : null
                          }
                        />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "DATE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ICV SCORE") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.value}%
                        </Text>
                        <Progress
                          variant="table"
                          colorScheme="brandScheme"
                          h="8px"
                          w="63px"
                          value={cell.value}
                        />
                      </Flex>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<ChevronDownIcon />}
                          variant="outline"
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              const selectedCompany = row.original.originalData;
                              localStorage.setItem(
                                "selectedCompany",
                                JSON.stringify(selectedCompany)
                              );
                              history.push("/admin/profile");
                            }}
                          >
                            View / Edit
                          </MenuItem>
                          <MenuItem onClick={() => setIsModalOpen(true)}>
                            Improve ICV Score
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ICV Score Improvement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select improvement percentage"
              onChange={handleSelectChange}
            >
              <option value="5%">5%</option>
              <option value="10%">10%</option>
              <option value="15%">15%</option>
            </Select>
            <Text mt="4">{suggestion}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
