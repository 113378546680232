// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import Information from "views/admin/profile/components/Information";

export default function GeneralInformation(props) {
  const { ...rest } = props;
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    if (selectedCompany) {
      setCompanyData(selectedCompany);
    }
  }, []);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  if (!companyData) {
    return null; // Or render a loading state or a message indicating no data
  }

  const {
    companyType,
    companyBasedIn,
    companyAddress,
    afsIssuedDate,
    financialYearEndDate,
    companyBusiness,
    originalCertificateIssuedDate,
    icvScoreValidTill,
    licenceNumber,
    companyName,
    companyLogo,
    companyEmail,
  } = companyData;

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        {companyName} - {companyEmail}
      </Text>
      <SimpleGrid columns="3" gap="20px">
        <Information
          boxShadow={cardShadow}
          title="Company Type"
          value={companyType || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Company Based In"
          value={companyBasedIn || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Company Address"
          value={companyAddress || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="AFS Issued Date"
          value={afsIssuedDate || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Financial Year End Date"
          value={financialYearEndDate || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Company Business"
          value={companyBusiness || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Original Certificate Issued Date"
          value={originalCertificateIssuedDate || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="ICV Score Valid Till"
          value={icvScoreValidTill || "N/A"}
        />
        <Information
          boxShadow={cardShadow}
          title="Licence Number"
          value={licenceNumber || "N/A"}
        />
      </SimpleGrid>
    </Card>
  );
}
