import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";

export const FirstStepAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.generalInformation);
  }, []);

  const handleInputChange = (e, section, field) => {
    setFormValues({
      ...formValues,
      [section]: {
        ...formValues[section],
        [field]: e.target.value,
      },
    });
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      >
      <Accordion defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Company Contact
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontSize="sm" mb={4}>
              Person Details
            </Text>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={formValues.companyContact?.name || ""}
                    onChange={(e) =>
                      handleInputChange(e, "companyContact", "name")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>E-mail</FormLabel>
                  <Input
                    value={formValues.companyContact?.email || ""}
                    onChange={(e) =>
                      handleInputChange(e, "companyContact", "email")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Office</FormLabel>
                  <Input
                    value={formValues.companyContact?.office || ""}
                    onChange={(e) =>
                      handleInputChange(e, "companyContact", "office")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Mobile</FormLabel>
                  <Input
                    value={formValues.companyContact?.mobile || ""}
                    onChange={(e) =>
                      handleInputChange(e, "companyContact", "mobile")
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Ownership
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Name of the Owner</FormLabel>
                  <Input
                    value={formValues.ownership?.ownerName || ""}
                    onChange={(e) =>
                      handleInputChange(e, "ownership", "ownerName")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Location</FormLabel>
                  <Input
                    value={formValues.ownership?.location || ""}
                    onChange={(e) =>
                      handleInputChange(e, "ownership", "location")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>
                    Internal Name of the Parent (Holding) Company
                  </FormLabel>
                  <Input
                    value={formValues.ownership?.parentCompanyName || ""}
                    onChange={(e) =>
                      handleInputChange(e, "ownership", "parentCompanyName")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Country of Parent's (Holding) Company</FormLabel>
                  <Input
                    value={formValues.ownership?.parentCompanyCountry || ""}
                    onChange={(e) =>
                      handleInputChange(e, "ownership", "parentCompanyCountry")
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Emiratization - Organization Structure
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>
                    Senior Management (CEO/COO/CFO/COO etc.)
                  </FormLabel>
                  <Input
                    value={
                      formValues.emiratizationOrganizationStructure
                        ?.seniorManagement || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "emiratizationOrganizationStructure",
                        "seniorManagement"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Emirati Number</FormLabel>
                  <Input
                    value={
                      formValues.emiratizationOrganizationStructure
                        ?.emiratiNumber || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "emiratizationOrganizationStructure",
                        "emiratiNumber"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Expat Number</FormLabel>
                  <Input
                    value={
                      formValues.emiratizationOrganizationStructure
                        ?.expatNumber || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "emiratizationOrganizationStructure",
                        "expatNumber"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Relationship with SMEs
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Number of SMEs dealt with</FormLabel>
                  <Input
                    value={formValues.relationshipWithSMEs?.numberOfSMEs || ""}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "relationshipWithSMEs",
                        "numberOfSMEs"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>
                    Value of Goods / Services procured from SMEs (AED)
                  </FormLabel>
                  <Input
                    value={
                      formValues.relationshipWithSMEs?.valueOfGoodsOrServices ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "relationshipWithSMEs",
                        "valueOfGoodsOrServices"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Research & Development
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Amount spent on R&D (AED)</FormLabel>
                  <Input
                    value={formValues.researchAndDevelopment?.amountSpent || ""}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "researchAndDevelopment",
                        "amountSpent"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Brief description of R&D activities</FormLabel>
                  <Input
                    value={formValues.researchAndDevelopment?.description || ""}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "researchAndDevelopment",
                        "description"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Local Supplier Development (LSD)
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>
                    Number of Local Suppliers developed / under development
                  </FormLabel>
                  <Input
                    value={
                      formValues.localSupplierDevelopment
                        ?.numberOfLocalSuppliers || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "localSupplierDevelopment",
                        "numberOfLocalSuppliers"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Amount spent on LSD (AED)</FormLabel>
                  <Input
                    value={
                      formValues.localSupplierDevelopment?.amountSpent || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "localSupplierDevelopment",
                        "amountSpent"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>
                    Brief description of LSD activities (name of Local Suppliers
                    & areas of development)
                  </FormLabel>
                  <Input
                    value={
                      formValues.localSupplierDevelopment?.description || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "localSupplierDevelopment",
                        "description"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Training & Development - Particular
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Emiratis</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.emiratis?.number || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "emiratis.number"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Number</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.emiratis?.spend || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "emiratis.spend"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Spend (AED)</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.emiratis?.spend || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "emiratis.spend"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Training & Development - Particular
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Expats</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.expats?.number || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "expats.number"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Number</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.expats?.spend || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "expats.spend"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Spend (AED)</FormLabel>
                  <Input
                    value={
                      formValues.trainingAndDevelopment?.expats?.spend || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "trainingAndDevelopment",
                        "expats.spend"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Corporate Social Responsibility
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>Activity</FormLabel>
                  <Input
                    value={
                      formValues.corporateSocialResponsibility?.activity || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "corporateSocialResponsibility",
                        "activity"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Beneficiary</FormLabel>
                  <Input
                    value={
                      formValues.corporateSocialResponsibility?.beneficiary ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "corporateSocialResponsibility",
                        "beneficiary"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Amount Spent (AED)</FormLabel>
                  <Input
                    value={
                      formValues.corporateSocialResponsibility?.amountSpent ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "corporateSocialResponsibility",
                        "amountSpent"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
