import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Link,
  Stack,
  useColorModeValue,
  useDisclosure,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdLock } from "react-icons/md";
import { HamburgerIcon } from "@chakra-ui/icons";
import homeImg from "assets/img/dashboards/homepage.png";
import SidebarBrand from "components/sidebar/components/Brand";

export default function HomePage() {
  const brandColor = useColorModeValue("brand.500", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  return (
    <Box>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding=".2rem"
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.800", "white")}
      >
        <Flex align="center" p={5} mr={15}>
          <Heading as="h1" ml={10} size="lg" letterSpacing={"tighter"}>
            <SidebarBrand />
          </Heading>
        </Flex>
        <Box display={{ base: "block", md: "none" }} onClick={onOpen}>
          <HamburgerIcon w={8} h={8} />
        </Box>
        <Box display={{ base: "none", md: "block" }} flexBasis={{ base: "100%", md: "auto" }}>
          <Stack
            spacing={8}
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "row", "row", "row"]}
            pt={[4, 4, 0, 0]}
          >
            <Link as={RouterLink} to="/" p={2}>
              Home
            </Link>
            <Link as={RouterLink} to="/about" p={2}>
              About us
            </Link>
            <Link as={RouterLink} to="/services" p={2}>
              Services
            </Link>
            <Link as={RouterLink} to="/testimonials" p={2}>
              Testimonials
            </Link>
            <Button
              as={RouterLink}
              to="/register"
              variant="solid"
              colorScheme="brandScheme"
              size="sm"
            >
              Register
            </Button>
            <Button
              variant="outline"
              colorScheme="brandScheme"
              onClick={() => history.push("/auth/sign-in")}
              size="sm"
              leftIcon={
                <Icon as={MdLock} width="20px" height="20px" color="inherit" />
              }
            >
              Sign in
            </Button>
          </Stack>
        </Box>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <SidebarBrand />
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <Link as={RouterLink} to="/" p={2} onClick={onClose}>
                Home
              </Link>
              <Link as={RouterLink} to="/about" p={2} onClick={onClose}>
                About us
              </Link>
              <Link as={RouterLink} to="/services" p={2} onClick={onClose}>
                Services
              </Link>
              <Link as={RouterLink} to="/testimonials" p={2} onClick={onClose}>
                Testimonials
              </Link>
              <Button
                as={RouterLink}
                to="/register"
                variant="solid"
                colorScheme="brandScheme"
                size="sm"
                onClick={onClose}
              >
                Register
              </Button>
              <Button
                variant="outline"
                colorScheme="brandScheme"
                onClick={() => {
                  history.push("/auth/sign-in");
                  onClose();
                }}
                size="sm"
                leftIcon={<Icon as={MdLock} width="20px" height="20px" color="inherit" />}
              >
                Sign in
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box padding="2rem">
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          pl={{ base: 4, md: 16 }}
          pr={{ base: 4, md: 16 }}
          pt={14}
        >
          <Box maxW="lg" order={{ base: 2, md: 1 }}>
            <Heading as="h2" size="xl" mb="4">
              Streamlining ICV Certification with AI Powered Efficiency
            </Heading>
            <Text fontSize="lg" my="12" mb="14">
              Streamline your ICV certification with our AI-powered portal.
              Enjoy a seamless, user-friendly experience from submission to
              approval. Elevate efficiency with real-time data processing.
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing="4">
              <Button
                as={RouterLink}
                to="/contact"
                colorScheme="brandScheme"
                size="lg"
                rightIcon={<AiOutlineArrowRight />}
              >
                Contact Now
              </Button>
              <Button
                as={RouterLink}
                to="/demo"
                variant="outline"
                colorScheme="brandScheme"
                size="lg"
              >
                Book a Demo Today
              </Button>
            </Stack>
            <Text fontSize="md" mt="12">
              Rated 4.9 out of 1200 reviews
            </Text>
          </Box>
          <Box mt={{ base: "0", md: "0" }} mb={{ base: "10", md: "0" }} order={{ base: 1, md: 2 }} w={{ base: "90vw", md: "auto" }}>
            <Image src={homeImg} alt="Business meeting" borderRadius="lg" width="100%" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
