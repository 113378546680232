import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const AdvancedTechnologyAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.advancedTechnology);
  }, []);

  const handleInputChange = (e, section, field) => {
    setFormValues({
      ...formValues,
      [section]: {
        ...formValues[section],
        [field]: e.target.value,
      },
    });
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Goods Manufacturers Only
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <FormLabel>
                Industrial Technology Transformation Index (ITTI) score
              </FormLabel>
              <Input
                value={formValues.goodsManufacturers?.ittiScore || ""}
                onChange={(e) =>
                  handleInputChange(e, "goodsManufacturers", "ittiScore")
                }
                placeholder="Text"
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Goods Manufacturers Only
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>ISO 14001</FormLabel>
                  <Input
                    value={formValues.goodsManufacturers?.iso14001 || ""}
                    onChange={(e) =>
                      handleInputChange(e, "goodsManufacturers", "iso14001")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>ISO 14046</FormLabel>
                  <Input
                    value={formValues.goodsManufacturers?.iso14046 || ""}
                    onChange={(e) =>
                      handleInputChange(e, "goodsManufacturers", "iso14046")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Green Industries Labelling</FormLabel>
                  <Input
                    value={
                      formValues.goodsManufacturers?.greenIndustriesLabelling ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "goodsManufacturers",
                        "greenIndustriesLabelling"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>ISO 50001</FormLabel>
                  <Input
                    value={formValues.goodsManufacturers?.iso50001 || ""}
                    onChange={(e) =>
                      handleInputChange(e, "goodsManufacturers", "iso50001")
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Service Providers Only
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>
                    ISO Sustainability Strategy and Governance
                  </FormLabel>
                  <Input
                    value={
                      formValues.serviceProviders?.isoSustainabilityStrategy ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "serviceProviders",
                        "isoSustainabilityStrategy"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Material Circularity</FormLabel>
                  <Input
                    value={
                      formValues.serviceProviders?.materialCircularity || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "serviceProviders",
                        "materialCircularity"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Water and Wastewater Management</FormLabel>
                  <Input
                    value={
                      formValues.serviceProviders
                        ?.waterAndWastewaterManagement || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "serviceProviders",
                        "waterAndWastewaterManagement"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Emissions Management</FormLabel>
                  <Input
                    value={
                      formValues.serviceProviders?.emissionsManagement || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "serviceProviders",
                        "emissionsManagement"
                      )
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Service Providers Only
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>ISO 14001</FormLabel>
                  <Input
                    value={formValues.serviceProviders?.iso14001 || ""}
                    onChange={(e) =>
                      handleInputChange(e, "serviceProviders", "iso14001")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>ISO 50001</FormLabel>
                  <Input
                    value={formValues.serviceProviders?.iso50001 || ""}
                    onChange={(e) =>
                      handleInputChange(e, "serviceProviders", "iso50001")
                    }
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
