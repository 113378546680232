import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  HStack,
  VStack,
  Icon,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import {
  MdCheckCircle,
  MdCancel,
  MdAutorenew,
  MdArrowForward,
} from "react-icons/md";
import { AdvancedTechnologyAccordion } from "./components/AdvancedTechnologyAccordion";
import { RevenueAccordion } from "./components/RevenueAccordion";
import { ManpowerExpatAccordion } from "./components/ManpowerExpatAccordion";
import { ManpowerEmiratizationAccordion } from "./components/ManpowerEmiratizationAccordion";
import { InvestmentAccordion } from "./components/InvestmentAccordion";
import { ThirdPartySpendAccordion } from "./components/ThirdPartySpendAccordion";
import { GoodsManufacturedAccordion } from "./components/GoodsManufacturedAccordion";
import { FirstStepAccordion } from "./components/FirstStepAccordion";
import { SupportingDocuments } from "./components/SupportingDocuments";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApprovedAnimation from "./components/approvedAnimation";

// Define the initial steps
const initialSteps = [
  { title: "Supporting Documents", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "General Information", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Goods Manufactured", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Third Party Spend", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Investment", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Manpower - Emiratization", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Manpower - Expat", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Revenue", status: "Pending", progress: 0, uploadStatus: "Pending" },
  { title: "Advanced Technology", status: "Pending", progress: 0, uploadStatus: "Pending" },
];

const documentsToBeUploaded = [
  {
    title: "Supporting Documents", "header": "General Information",
    bulletpoints: [
      "Passport & Emirates ID of all the owners/partners/managers mentioned in the Trade License and POA, MOA, AOA, If company is shareholder then Cerficate of Incorporation",
      "Latest Audited financial statements (Signed by Auditor and Authorised Signatory)",
      "Auditors MOE license ( Auditor signing the FS) & Professional License of the firm.",
      "Latest Trade license",
      "VAT certificate (without edit/hidden information)",
      "Previous ICV certificate (if any)",
      "Trial Balance with mappings/groupings from auditors in excel",
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "General Information",
    header: "Suppliers in UAE - (Excel format)",
    bulletpoints: [
      "Supplier wise purchases/expenses for the full year, which must show the 1. taxable value and tax, 2. with location of emirate in UAE, if outside UAE then send the name of the country, 3. Type of Goods / Services Description, in excel.",
      "ICV certificate of suppliers"
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Goods Manufactured",
    header: "Rent",
    bulletpoints: [
      "Rental agreement for audited year and previous year"
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Third Party Spend",
    header: "Leases- ROU- send the IFRS-16 workings and lease agreement",
    bulletpoints: [],
    status: "Pending",
    progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Manpower - Emiratization",
    header: "Salary ",
    bulletpoints: [
      "Emiratis ID Documents (if any Emiratis are employed), signed labour contract, PP, Labour Card & EID",
      "Emiraties pension fund calculation for each month, invoices, payment proof and pension statement",
      "SIF File & exchange receipt for 12 month ",
      "Bank statements for Salary transfer for all the month",
      "Payroll file for 12 months",
      "Visa cancellation documents ",
      "Schedule for gratuity with employee details , opening & closing balance, leave salary and bonus paid details "
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Advanced Technology",
    header: "Revenue",
    bulletpoints: [
      "Revenue ledger- Bifurcation of local revenue and export revenue in excel format. ",
      "For the total export listing, either 30 samples or 30% of the transaction or transaction value coverage of 50% whichever is lower",
      "Sales invoice must be issued to an entity outside UAE, Chamber of commerce certificae and customs documents and swift copy/ bank statement "
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Revenue",
    header: "Managerial Remuneration / License Exp / Utilities / Communication Exp / Govt Exp",
    bulletpoints: [],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Investment",
    header: "Assets",
    bulletpoints: [
      "Fixed asset register for the year in excel with depreciation and invoices for additions , vehicles all the mulkiya copies"
    ],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
  {
    title: "Manpower - Expat",
    header: "Manpower count / MOL list / NAFIS Registration / Sponsorship fee ",
    bulletpoints: [],
    status: "Pending", progress: 0, uploadStatus: "Pending"
  },
];


const FormRequest = () => {
  const [steps, setSteps] = useState(initialSteps);
  const [activeStep, setActiveStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isProgressActive, setIsProgressActive] = useState(false);
  const intervals = [];
  const [userRole, setUserRole] = useState("");
  const [companyStatus, setCompanyStatus] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");

  const [newUploadedFiles, setNewUploadedFiles] = useState({})

  const history = useHistory();

  const startFakeProgress = () => {
    // setIsProgressActive(true);
    onOpen();
  };

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    setUserRole(role);

    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    // alert(selectedCompany !== null)
    setSelectedCompany(selectedCompany)
    if (selectedCompany && selectedCompany.status) {
      setCompanyStatus(selectedCompany.status);
    }


    const uploadedFiles = JSON.parse(localStorage.getItem("uploadedFiles"));
    if (uploadedFiles) {
      // Perform any required actions with the uploaded files data
      setNewUploadedFiles(uploadedFiles)
    }

    let interval;
    if (isNextDisabled) {
      setIsProgressActive(true);
      interval = setInterval(() => {
        setSteps((prevSteps) => {
          const newSteps = [...prevSteps];
          const currentStep = newSteps[activeStep];
          if (currentStep.progress < 100) {
            currentStep.progress += 10;
          } else {
            currentStep.uploadStatus = "Approved";
            if (activeStep + 1 < newSteps.length) {
              newSteps[activeStep + 1].uploadStatus = "Pending";
              setActiveStep(activeStep + 1);
            } else {
              clearInterval(interval);
              setIsProgressActive(false);

            }
          }
          return newSteps;
        });
      }, 500);
      intervals.push(interval);
    }
    return () => clearInterval(interval);
  }, [isProgressActive, activeStep, isOpen, isNextDisabled]);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (userRole === 'aicv' && selectedCompany.status === 'pending') {
        onOpen()
        setTimeout(() => {
          onClose()
          handleApprove()
        }, 2000)
      } else {
        handleSubmit();
      }
      // startFakeProgress();
    } else {
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[activeStep].status = "Approved";
        if (activeStep + 1 < newSteps.length) {
          newSteps[activeStep + 1].status = "In Progress";
        }
        return newSteps;
      });
      setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handlePrevious = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      newSteps[activeStep].status = "Pending";
      if (activeStep - 1 >= 0) {
        newSteps[activeStep - 1].status = "In Progress";
      }
      return newSteps;
    });
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const clearAllIntervals = () => {
    while (intervals.length) {
      const intervalId = intervals.pop();
      clearInterval(intervalId);
    }
  }
  const handleCancel = () => {
    clearAllIntervals()
    setActiveStep(0);
    setSteps(initialSteps);
    setIsProgressActive(false);
    onClose();
    window.location.reload();
  };

  const companiesList = {
    "Data Cell Solution": "dataCellSolution"
  }

  const handleSubmit = () => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));

    if (!selectedCompany) {
      alert("Upload a valid company file name");
      return;
    }


    if (activeStep === steps.length - 1) {
      if (userRole !== "aicv") {
        if (!selectedCompany.status || selectedCompany.status === '') {

          if (Object.keys(newUploadedFiles).length > 0) {
            selectedCompany.files = newUploadedFiles;
          }

          selectedCompany.requestId = '110042';
          selectedCompany.status = 'pending';

          localStorage.setItem("selectedCompany", JSON.stringify(selectedCompany));

          const storedData = JSON.parse(localStorage.getItem("companyData"));
          if (storedData) {
            const companyKey = companiesList["Data Cell Solution"];
            if (storedData.companies[companyKey]) {

              if (Object.keys(newUploadedFiles).length > 0) {
                storedData.companies[companyKey].files = newUploadedFiles;
              }

              storedData.companies[companyKey].requestId = 110042;
              storedData.companies[companyKey].status = "pending";


              localStorage.setItem("companyData", JSON.stringify(storedData));
            } else {
              alert(`Company key "${companyKey}" not found in storedData.companies`);
            }
          } else {
            alert("No companyData found in localStorage");
          }
        }
      }
      history.push("/admin/profile");
    } else {
      onOpen();
      setIsNextDisabled(true);
    }
  };

  const handleApprove = () => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));

    if (!selectedCompany) {
      alert("Upload a valid company file name");
      return;
    }

    if (activeStep === steps.length - 1) {
      if (userRole === "aicv") {
        if (!selectedCompany.status || selectedCompany.status === 'pending') {
          selectedCompany.status = 'Approved';
          localStorage.setItem("selectedCompany", JSON.stringify(selectedCompany));

          const storedData = JSON.parse(localStorage.getItem("companyData"));
          if (storedData) {
            const companyKey = companiesList["Data Cell Solution"];
            if (storedData.companies[companyKey]) {
              storedData.companies[companyKey].status = "Approved";
              localStorage.setItem("companyData", JSON.stringify(storedData));
            } else {
              alert(`Company key "${companyKey}" not found in storedData.companies`);
            }
          } else {
            alert("No companyData found in localStorage");
          }
        }
      }
      history.push("/admin/profile");
    } else {
      onOpen();
      setIsNextDisabled(true);
    }
  };


  const renderAccordion = (index) => {
    switch (index) {
      case 0: return <SupportingDocuments initialSteps={documentsToBeUploaded} />;
      case 1: return <FirstStepAccordion />;
      case 2: return <GoodsManufacturedAccordion />;
      case 3: return <ThirdPartySpendAccordion />;
      case 4: return <InvestmentAccordion />;
      case 5: return <ManpowerEmiratizationAccordion />;
      case 6: return <ManpowerExpatAccordion />;
      case 7: return <RevenueAccordion />;
      case 8: return <AdvancedTechnologyAccordion />;
      default: return null;
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Progress
        value={(activeStep / (steps.length - 1)) * 100}
        size="xs"
        colorScheme="blue"
        mt={4}
      />

      <HStack spacing="24px" align="center" justify="center" mb={4}>
        {steps.map((step, index) => (
          <VStack key={index} align="center">
            <Flex align="center">
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={
                  index === activeStep
                    ? "blue.500"
                    : step.status === "Approved"
                      ? "green.500"
                      : step.status === "Disable"
                        ? "red.500"
                        : "gray.500"
                }
                as={
                  step.status === "Approved"
                    ? MdCheckCircle
                    : step.status === "Disable"
                      ? MdCancel
                      : step.status === "In Progress"
                        ? MdAutorenew
                        : MdArrowForward
                }
              />
              <Text
                fontSize="sm"
                fontWeight={index === activeStep ? "bold" : "normal"}
                whiteSpace="nowrap"
              >
                {step.title}
              </Text>
            </Flex>
          </VStack>
        ))}
      </HStack>

      {renderAccordion(activeStep)}

      <Box mt={4}>
        <Flex justify="space-between">
          <Button variant="ghost" onClick={handleCancel} disabled={activeStep === 0}>
            Cancel
          </Button>
          <Stack direction="row" spacing={4}>
            <Button variant="outline" onClick={handlePrevious} disabled={activeStep === 0}>
              Previous
            </Button>
            {userRole !== 'aicv' && (selectedCompany === undefined || selectedCompany === null) && (
              <Button colorScheme="teal" onClick={handleSubmit}>
                Submit
              </Button>
            )}
            {userRole === 'aicv' && <Button colorScheme="teal" onClick={handleNext}>
              {activeStep === steps.length - 1
                ? (companyStatus !== "Approved" ? "Approve" : "Finish")
                : "Next"}
            </Button>}

            {/* activeStep === steps.length - 1 && !isNextDisabled */}
            {userRole !== 'aicv' && <Button colorScheme="teal" onClick={handleNext} disabled={selectedCompany === null}>
              {activeStep === steps.length - 1
                ? (companyStatus !== "Approved" ? "Finish" : "Finish")
                : "Next"}
            </Button>}
          </Stack>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>AICV</ModalHeader>
          <ModalBody>
            <Text mb={4}>Verifying and processing all documents</Text>

            {userRole !== 'aicv' && activeStep === steps.length - 1 &&
              <Box mb={3}>
                <ApprovedAnimation />
              </Box>}

            {activeStep !== steps.length - 1 && steps.map((step, index) => (
              <Box key={index} mb={3}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Flex>
                    <Icon
                      as={
                        step.uploadStatus === "Approved"
                          ? MdCheckCircle
                          : step.uploadStatus === "Pending"
                            ? MdAutorenew
                            : MdAutorenew
                      }
                      color={
                        step.uploadStatus === "Approved"
                          ? "green.500"
                          : step.uploadStatus === "Pending"
                            ? "blue.500"
                            : "gray.500"
                      }
                    />
                    <Text style={{ marginLeft: 5 }} fontWeight="bold">{step.title}</Text>
                  </Flex>
                  <Text>{step.progress}%</Text>
                </Flex>
                <Progress value={step.progress} size="sm" width={450} colorScheme="purple" />
              </Box>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={handleCancel} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="purple"
              isLoading={activeStep !== 8}
              onClick={() => {
                onClose();
                setIsNextDisabled(false);
              }}
            >
              Finish
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  );
};

export default FormRequest;
