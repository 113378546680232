// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import CheckTable from "views/admin/dataTables/components/CheckTable";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React from "react";
import { columnsDataDevelopmentCompany } from "./variables/columnsData";
import { DevelopmentTableCompany } from "./components/DevelopmentTable";

export default function Settings() {
  // Chakra Color Mode
  let userRole = localStorage.getItem("userRole")

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {userRole === "aicv" && <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={[
            {
              "COMPANY NAME": "Seacorr Engineering Services LLC",
              "STATUS": "Approved",
              // "STATUS": ["apple", "android", "windows"],
              "date": "12.Jan.2021",
              "ICV SCORE": 75.5,
              "ACTION": "View / Edit"
            },
          ]}
        />}

        {userRole === "company" && <DevelopmentTableCompany
          columnsData={columnsDataDevelopmentCompany}
          tableData={[
            {
              "ICV ID": "99991",
              "STATUS": "Approved",
              // "STATUS": ["apple", "android", "windows"],
              "date": "12.Jan.2021",
              "ICV SCORE": 75.5,
              "ACTION": "View / Edit"
            },
          ]}
        />}

        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
      </SimpleGrid>
    </Box>
  );
}
