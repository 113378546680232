import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const RevenueAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.revenue);
  }, []);

  const handleInputChange = (e, field) => {
    setFormValues({
      ...formValues,
      [field]: e.target.value,
    });
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Revenue
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <FormLabel>From UAE Customers</FormLabel>
                  <Input
                    value={formValues.fromUaeCustomers || ""}
                    onChange={(e) => handleInputChange(e, "fromUaeCustomers")}
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Exports excluding re-exports</FormLabel>
                  <Input
                    value={formValues.exportsExcludingReExports || ""}
                    onChange={(e) =>
                      handleInputChange(e, "exportsExcludingReExports")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>From Outside UAE Customers</FormLabel>
                  <Input
                    value={formValues.fromOutsideUaeCustomers || ""}
                    onChange={(e) =>
                      handleInputChange(e, "fromOutsideUaeCustomers")
                    }
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>Total</FormLabel>
                  <Input
                    value={formValues.total || ""}
                    onChange={(e) => handleInputChange(e, "total")}
                    placeholder="Text"
                  />
                </Box>
                <Box>
                  <FormLabel>
                    Re-Exports (export of goods without value addition)
                  </FormLabel>
                  <Input
                    value={formValues.reExports || ""}
                    onChange={(e) => handleInputChange(e, "reExports")}
                    placeholder="Text"
                  />
                </Box>
              </SimpleGrid>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
