// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";

// Helper function to format numbers
const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
};

export default function Banner() {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    if (selectedCompany) {
      setCompanyData(selectedCompany);
    }
  }, []);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  if (!companyData) {
    return <Text>Loading...</Text>;
  }

  const {
    companyLogo,
    companyBanner,
    companyName,
    companyEmail,
    generalInformation,
  } = companyData;

  const employees = generalInformation.emiratizationOrganizationStructure.expatNumber +
    generalInformation.emiratizationOrganizationStructure.emiratiNumber;
  const capital = companyData.revenue.fromUaeCustomers;
  const icvRequests = companyData.status === "Approved" ? 1 : 0; // Adjust this logic as per your actual data

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Box
        bg={`url(${companyBanner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={companyLogo}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {companyName}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {companyEmail}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {formatNumber(employees)}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Employees
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {formatNumber(capital)}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Capital
          </Text>
        </Flex>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {formatNumber(icvRequests)}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            ICV Requests
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
