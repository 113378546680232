import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, useColorModeValue } from "@chakra-ui/react";
import "./Application.css"; // Import the CSS file

const ApprovedAnimation = () => {
  const color = useColorModeValue("green.500", "green.200");
  const backgroundColor = useColorModeValue("white", "gray.800");
  const [show, setShow] = useState(true);

  const [selectedCompany, setSelectedCompany] = useState("");

  const handleButtonClick = () => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 10);
  };

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setSelectedCompany(selectedCompany);
  }, []);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="500px"
      backgroundColor={backgroundColor}
      textAlign="center"
    >
      {show && (
        <Box className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </Box>
      )}
      <Text fontSize="2xl" fontWeight="bold" color={color} mt="4">
        {selectedCompany.ICVScore}
      </Text>
      <Text fontSize="lg" color="gray.600" mt="2">
        ICV Score Results
      </Text>
      {/* <Box mt="4">
        <Button colorScheme="green" onClick={handleButtonClick}>
        28.57%
        </Button>
      </Box> */}
    </Flex>
  );
};

export default ApprovedAnimation;
