import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
} from "@chakra-ui/react";

export const InvestmentAccordion = () => {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));
    setFormValues(selectedCompany.investment);
  }, []);

  const handleInputChange = (e, section, field, index) => {
    if (index !== undefined) {
      setFormValues({
        ...formValues,
        [section]: formValues[section].map((item, i) =>
          i === index ? { ...item, [field]: e.target.value } : item
        ),
      });
    } else {
      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          [field]: e.target.value,
        },
      });
    }
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Investment
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FormControl>
              <FormLabel>
                UAE Assets Net Book Value (AED) in previous Financial Year
              </FormLabel>
              <Input
                value={formValues.uaeAssetsNetBookValuePreviousYear || ""}
                onChange={(e) =>
                  handleInputChange(e, "uaeAssetsNetBookValuePreviousYear", "")
                }
                placeholder="Text"
              />
            </FormControl>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Owned Assets
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <FormControl>
                  {formValues.ownedAssets?.map((asset, index) => (
                    <SimpleGrid columns={2} spacing={4} key={index}>
                      <Box>
                        <FormLabel>Owned Assets Description</FormLabel>
                        <Input
                          value={asset.description || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "ownedAssets",
                              "description",
                              index
                            )
                          }
                          placeholder="Text"
                        />
                      </Box>
                      <Box>
                        <FormLabel>Location</FormLabel>
                        <Input
                          value={asset.location || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "ownedAssets",
                              "location",
                              index
                            )
                          }
                          placeholder="Text"
                        />
                      </Box>
                      <Box>
                        <FormLabel>Net Book Value (AED)</FormLabel>
                        <Input
                          value={asset.netBookValue || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "ownedAssets",
                              "netBookValue",
                              index
                            )
                          }
                          placeholder="Text"
                        />
                      </Box>
                    </SimpleGrid>
                  ))}
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
