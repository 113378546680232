import { useState, useEffect } from "react";
import { MdDelete, MdUpload } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  SimpleGrid,
  Box,
  FormLabel,
  Input,
  AccordionIcon,
  InputLeftElement,
  InputGroup,
  Icon,
  Text,
  Flex,
  Button,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoOutlineIcon } from "@chakra-ui/icons";


export const SupportingDocuments = ({ initialSteps }) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedCompany, setUploadedCompany] = useState({});

  const demoCompanies = ["dataCellSolution"];

  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    const selectedCompany = JSON.parse(localStorage.getItem("selectedCompany"));

    if (!selectedCompany) localStorage.removeItem("selectedCompany");
    if (userRole === "aicv" || selectedCompany !== "") {
      if (selectedCompany && selectedCompany.files) {
        setUploadedFiles(selectedCompany.files);
      }
    }
    return () => {
      localStorage.setItem("uploadedFiles", JSON.stringify(uploadedCompany));
    };
  }, []);

  const handleFileChange = (e, stepTitle) => {
    const file = e.target.files[0];
    const companiesData = JSON.parse(localStorage.getItem("companyData"));

    if (companiesData && demoCompanies.includes(file.name.split(".")[0])) {
      const selectedCompany = JSON.stringify(
        companiesData.companies.dataCellSolution
      );
      localStorage.setItem("selectedCompany", selectedCompany);
    }

    if (file) {
      setUploadedFiles((prevFiles) => {
        uploadedCompany[stepTitle] = file.name.split(".")[0];
        setUploadedCompany(uploadedCompany);

        return {
          ...prevFiles,
          [stepTitle]: file,
        };
      });
    }
  };

  const handleFileDelete = (stepTitle) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[stepTitle];
      return newFiles;
    });
  };

  return (
    <Box p={5} boxShadow="lg" borderRadius="md" bg="white">
      <Text fontSize="2xl" fontWeight="bold" mb={6}>
        Supporting Documents
      </Text>
      <FormControl>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          {initialSteps.map((step, index) => (
            <Box key={index} p={4} border="1px" borderColor="gray.200" borderRadius="md">
              <FormLabel fontSize="lg" fontWeight="semibold" mb={2}>
                {step.header}
              </FormLabel>
              {step.bulletpoints.length > 0 && (
                <List spacing={2} mb={4}>
                  {step.bulletpoints.map((point, idx) => (
                    <ListItem key={idx}>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      {point}
                    </ListItem>
                  ))}
                </List>
              )}
              <InputGroup mt={2}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={MdUpload} color="gray.400" />
                </InputLeftElement>
                <Input
                  type="file"
                  onChange={(e) => handleFileChange(e, step.title)}
                  display={uploadedFiles[step.title] ? "none" : "block"}
                  pl="10"
                />
                {uploadedFiles[step.title] && (
                  <Flex align="center" w="100%" mt={2}>
                    <Text pl="10" isTruncated>
                      {uploadedFiles[step.title].name
                        ? uploadedFiles[step.title].name
                        : uploadedFiles[step.title]}
                    </Text>
                    <Icon
                      as={MdDelete}
                      color="red.500"
                      cursor="pointer"
                      ml="auto"
                      onClick={() => handleFileDelete(step.title)}
                    />
                  </Flex>
                )}
              </InputGroup>
            </Box>
          ))}
        </SimpleGrid>
      </FormControl>
      {/* <HStack mt={8} spacing={4}>
        <Button colorScheme="blue" size="lg">
          Save
        </Button>
        <Button variant="outline" size="lg">
          Cancel
        </Button>
      </HStack> */}
    </Box>
  );
};
